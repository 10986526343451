import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../../styles/about-section.css";
import logo from "./logo.png";

const AboutSection = ({ aboutClass }) => {
  return (
    <section
      className="about__section"
      style={
        aboutClass === "aboutPage"
          ? { marginTop: "0px" }
          : { marginTop: "40px" }
      }
    >
      <Container>
        <Row>
          <Col lg="6" md="6">
              <h4 className="section__subtitle">About Us</h4>
              <h2 className="section__title">Welcome to PurpleCap Gaming</h2>
              <p className="section__description">
              Sorry for the inconvenience,<br/> content will be uploaded soon
              </p>

            
          </Col>

          <Col lg="6" md="6">
            <div className="about__img">
              <img src={logo} alt="" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutSection;
