import React from "react";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import GameItem from "../components/UI/GameItem";
import gameData from "../assets/data/gameData";
import "../styles/game-item.css"

const GameListing = () => {
  return (
    <Helmet title="Games">
      

      <section className="topmargin">
        <Container>
          <Row>

            {gameData.map((item) => (
              <GameItem item={item} key={item.id} />
            ))}
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default GameListing;
