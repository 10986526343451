import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import GameListing from "../pages/GameListing";
import GameDetails from "../pages/GameDetails";
import NotFound from "../pages/NotFound";
import Contact from "../pages/Contact";
import PrivecyPolicy from "../pages/PrivecyPolicy";




const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home />}/>
      <Route path="/about" element={<About />}/>
      <Route path="/games" element={<GameListing/>} />
      <Route path="/games/:slug" element={<GameDetails/>} />
      <Route path="/contact" element={<Contact/>} />
      <Route path="/PrivecyPolicy" element={ <PrivecyPolicy/>} />
     
    
    
      
    </Routes>
  );
};

export default Routers;
