import React from "react";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/game-item.css";
import { FaDownload } from "react-icons/fa";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { Scrollbars } from 'react-custom-scrollbars';


const GameItem = (props) => {
  const { imgUrl, gameName, describe, gameUrl, gamePlyStrUrl } = props.item;

  return (

    <Col lg="4" md="4" sm="6" className="mb-5 " >
    
        <div className="game__item">
          <div className="game__img">
            <img src={imgUrl} alt="" className="w-100" />
          </div>

          <div className="game__item-content mt-4">
            <h4 className="section__title text-center">{gameName}</h4>
            <Scrollbars style={{ height: "100px" }}>
              <h6 className="game__describe text-center mt-">
                {describe}
              </h6>
            </Scrollbars>

            <Link to={`${gamePlyStrUrl}`}>
              <button id="bw" className="game__item-btn game__btn-details">
                <IoLogoGooglePlaystore style={{ color: '#fff' }} />
              </button>
            </Link>

            <Link to={`${gameUrl}`}>
              <button id="bw" className="game__item-btn game__btn-details">
                <FaDownload style={{ color: '#fff' }} />
              </button>
            </Link>

          </div>
        </div>

    </Col>

  );
};

export default GameItem;
