import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import "../styles/contact.css";
import logo from "./logo.png";

const socialLinks = [
  {
    url: "#",
    icon: "ri-facebook-line",
  },
  {
    url: "#",
    icon: "ri-instagram-line",
  },
  {
    url: "#",
    icon: "ri-linkedin-line",
  },
  {
    url: "#",
    icon: "ri-twitter-line",
  },
];

const Contact = () => {
  const [formStatus, setFormStatus] = React.useState('Send')
  const onSubmit = (e) => {
    e.preventDefault()
    setFormStatus('Sent')
    const { name, email, message } = e.target.elements
    let conFom = {
      name: name.value,
      email: email.value,
      message: message.value,
    }
    console.log(conFom)
  }

  return (
    <Helmet title="Contact">

      <section className="align__contact" >
        <div className="back">
          <div className="contact__info">
            <img src={logo} className="imgsiz" />
            {/* <h2 className="contactfont">Contact us...</h2> */}
            <form onSubmit={onSubmit} className="formstil">
              <div className="mb-3 ">
                <label className="form-label" htmlFor="name">
                  Name:
                </label>
                <input className="form-control" type="text" id="name" required />
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="email">
                  Email:
                </label>
                <input className="form-control" type="email" id="email" required />
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="message">
                  Message:
                </label>
                <textarea className="form-control" id="message" required />
              </div>
              <button className="btnstil" type="submit">
                {formStatus}
              </button>
            </form>
          </div>
        </div>



      </section>
    </Helmet>
  );
};

export default Contact;
