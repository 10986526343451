import React from "react";
import Helmet from "../components/Helmet/Helmet";
import AboutSection from "../components/UI/AboutSection";
import { Container, Row, Col } from "reactstrap";
import "../styles/about.css";
import { FaPhoneAlt } from "react-icons/fa";

const About = () => {
  return (
    <Helmet title="About">

      <div className="topmargin">
        <AboutSection aboutClass="aboutPage" />

        <section className="about__page-section">
          <Container>
            <Row>


              <Col lg="12" md="12" sm="12">
                <div className="about__page-content">
                  <h2 className="section__title">
                    We Are Committed To Develop Games That Meet Or Exceed The Expectations Of Players.
                  </h2>



                  <div className=" d-flex align-items-center gap-3 mt-4">


                    <div>
                      <h6 className="section__subtitle">Need Any Help?</h6>
                      <h2 style={{ textShadow: "0px 0px 2px white" }}>
                        +91 84466-33897</h2>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>


      </div>

    </Helmet>
  );
};

export default About;
